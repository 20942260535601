<template>
  <section class="invoice-preview-wrapper">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    {{ $t("Order_Show.Mobilemasr") }}
                  </h3>
                </div>
                <p class="card-text mb-25">2 مدينة عباد الرحمن 1,</p>
                <p class="card-text mb-25">الطريق الدائرى, المعادى 11571</p>
                <p class="card-text mb-0">القاهرة مصر</p>
              </div>
              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ $t("Order_Show.Invoice") }}
                  <span class="invoice-number">#{{ orderData.code }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t("Order_Show.Date_Issued") }} :
                  </p>
                  <p class="invoice-date">
                    {{ orderData.created_at }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h5 class="mb-2">{{ $t("Order_Show.Invoice_to") }} :</h5>
                <h6 class="mb-25">
                  {{ username }}
                </h6>
                <p class="card-text mb-25">
                  {{ useraddress }}, {{ area }}, {{ city }}, {{ country }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.phoneone }} - {{ orderData.phonetwo }}
                </p>
                <p class="card-text mb-0">
                  {{ useremail }}
                </p>
              </b-col>
              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">{{ $t("Order_Show.Payment_Details") }} :</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          {{ $t("Order_Show.Total_Paid") }} :
                        </td>
                        <td>
                          <span class="font-weight-bold">{{
                            orderData.total_paid
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">{{ $t("Order_Show.Type") }} :</td>
                        <td>{{ orderData.type }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Invoice Description: Table -->
          <b-table-lite
            v-if="items.length > 0"
            responsive
            :items="items"
            :fields="[
              {
                key: 'product',
                label: this.$t('Table_Head.Product'),
              },
              {
                key: 'price',
                label: this.$t('Table_Head.Price'),
              },
              {
                key: 'qty',
                label: this.$t('Table_Head.Quantity'),
              },
              {
                key: 'total',
                label: this.$t('Table_Head.Total'),
              },
            ]"
          >
          </b-table-lite>
          <b-table-lite
            v-if="items2.length > 0"
            responsive
            :items="items2"
            :fields="[
              'Bundle',
              'price',
              'qty',
              'products',
              'product_qty',
              'total',
            ]"
          >
            <template #cell(products)="data">
              <div v-for="(ite, ind) in data.value" :key="ind">
                {{ ite.name }}
                <hr />
              </div>
            </template>
            <template #cell(product_qty)="data">
              <div v-for="(itme, index) in data.value" :key="index">
                {{ itme.quantity }}
                <hr />
              </div>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">
                    {{ $t("Order_Show.Sales_Person") }} :</span
                  >
                  <span v-if="orderData.vendor != null" class="ml-75">{{
                    vendorstroe
                  }}</span>
                  <span v-else class="ml-75"
                    >{{ $t("Order_Show.Mobilemasr") }}
                  </span>
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Subtotal") }} :
                    </p>
                    <p class="invoice-total-amount">
                      {{ orderData.total }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Discount") }} :
                    </p>
                    <p
                      v-if="orderData.discount != null"
                      class="invoice-total-amount"
                    >
                      {{ orderData.discount }}
                    </p>
                    <p v-else class="invoice-total-amount">-</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Tax") }} :
                    </p>
                    <p
                      v-if="orderData.tax != null"
                      class="invoice-total-amount"
                    >
                      {{ orderData.tax }}
                    </p>
                    <p v-else class="invoice-total-amount">-</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Fees") }} :
                    </p>
                    <p
                      v-if="orderData.fees != null"
                      class="invoice-total-amount"
                    >
                      {{ orderData.fees }}
                    </p>
                    <p v-else class="invoice-total-amount">-</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Shipping_Price") }} :
                    </p>
                    <p
                      v-if="orderData.shipping_price != null"
                      class="invoice-total-amount"
                    >
                      {{ orderData.shipping_price }}
                    </p>
                    <p v-else class="invoice-total-amount">-</p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("Order_Show.Total_Paid") }} :
                    </p>
                    <p class="invoice-total-amount">
                      {{ orderData.total_paid }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <!-- Note -->
          <!-- <b-card-body class="invoice-padding pt-0">
                        <span class="font-weight-bold">Note: </span>
                        <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
                      projects. Thank You!</span>
                    </b-card-body> -->
        </b-card>
      </b-col>
      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            {{ $t("Order_Show.Print") }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import Logo from "@core/layouts/components/Logo.vue";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    Logo,
  },

  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      showDismissibleAlert: false,
      errors_back: [],
      orderData: {},
      username: "",
      vendorname: "",
      useraddress: "",
      vendoraddress: "",
      userphone: "",
      vendorphone: "",
      useremail: "",
      vendoremail: "",
      vendorstroe: "",
      area: "",
      city: "",
      country: "",
      items: [],
      items2: [],
    };
  },
  setup() {
    const printInvoice = () => {
      window.print();
    };
    return {
      printInvoice,
    };
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("orders/" + this.id)
      .then((result) => {
        this.orderData = result.data.data;
        // this.orderData.created_at = moment(this.orderData.created_at).format("LLLL")
        this.username =
          this.orderData.user.firstname + " " + this.orderData.user.lastname;
        this.useraddress = this.orderData.user.address;

        this.userphone = this.orderData.user.phone;

        this.useremail = this.orderData.user.email;

        this.area = this.orderData.area.name;
        this.city = this.orderData.area.city.name;
        this.country = this.orderData.area.city.country.name;

        if (this.orderData.vendor != null) {
          this.vendorname =
            this.orderData.vendor.firstname +
            " " +
            this.orderData.vendor.lastname;
          this.vendoraddress = this.orderData.vendor.address;
          this.vendorphone = this.orderData.vendor.phone;
          this.vendoremail = this.orderData.vendor.email;
          this.vendorstroe = this.orderData.vendor.storename;
        }

        for (let index in this.orderData.variants) {
          this.items.push({
            product: this.orderData.variants[index].name,
            qty: this.orderData.variants[index].quantity,
            price: this.orderData.variants[index].price,
            total:
              this.orderData.variants[index].price *
              this.orderData.variants[index].quantity,
          });
        }
        // ['Bundle', 'price', 'qty','items','items Qty', 'total']
        for (let index in this.orderData.bundles) {
          this.items2.push({
            Bundle: this.orderData.bundles[index].title,
            qty: this.orderData.bundles[index].quantity,
            price: this.orderData.bundles[index].price,
            products: this.orderData.bundles[index].variants,
            product_qty: this.orderData.bundles[index].variants,
            total:
              this.orderData.bundles[index].price *
              this.orderData.bundles[index].quantity,
          });
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  } // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }
            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    } // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>